import ProviderService from '@/services/profile.service'
import router from '@/router'

const providerData: any = JSON.parse(localStorage.getItem('provider') || '{}')
const initialState = providerData
  ? { status: { loggedIn: true }, providerData }
  : { status: { loggedIn: false }, providerData: null }

export const profile = {
  namespaced: true,
  state: initialState,
  actions: {
    getProfile ({ commit }) {
      return ProviderService.getProfile().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('provider')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    editProfile ({ commit }, data: any) {
      return ProviderService.editProfile(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('provider')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
