<template>
  <LottieAnimation
    v-if="isLoading"
    :animationData="require('@/assets/preloader/load.json')"
  />
  <div class="login">
    <div class="card card-container">
      <Form
        v-if="this.sendCode"
        @submit="sendOtp"
      >
        <div class="form-group">
          <Field
            class="form-control"
            type="email"
            name="email"
            :placeholder="$t('message.email')"
            v-model="this.email"
          />
          <label
            for="email"
            :class="['form-group-label', this.email != null && this.email.length > 0 && 'active']"
          >{{$t('message.email')}}</label>
          <div v-if="this.errorLogin">
            <AlertBlock :message="this.errorLogin.email" />
          </div>
        </div>

        <div class="form-group">
          <button class="login__button">
            <span>{{ $t('message.login_form.get_the_code') }}</span>
          </button>
        </div>
      </Form>
      <Form
        v-if="!this.sendCode"
        @submit="login"
      >
        <div class="login__subtitle">
          {{ $t('message.login_form.please_check_your_mail') }}
        </div>

        <div class="form-group">
          <Field
            name="code"
            type="text"
            min="0"
            max="4"
            maxlength="4"
            :placeholder="$t('message.login_form.code')"
            class="form-control"
            v-model="this.code"
            pattern="[0-9]*"
          />
          <label
            for="code"
            :class="['form-group-label', (this.code + '').length > 0 && 'active']"
          >{{$t('message.login_form.code')}}</label>
          <ErrorMessage
            name="code"
            class="error-feedback"
          />
          <div v-if="this.errorCode">
            <AlertBlock :message="this.errorCode.login" />
            <AlertBlock :message="this.errorCode.code" />
          </div>
          <div v-if="this.errorLoginMessage && !this.errorCode">
            <div class="error">
              {{ this.errorLoginMessage }}
            </div>
          </div>
        </div>

        <div class="form-group login__back-group">
          <!-- <div
            class="login__button-back third-button"
            @click="goToChangeEmail()"
          >
            {{$t('message.back')}}
          </div> -->
          <button class="login__button-confirm first-button">
            <span class="spinner-border spinner-border-sm"></span>
            <span>{{ $t('message.confirm') }}</span>
          </button>
        </div>

        <div class="login__resend-group">
          <div class="timer">
            {{ $t("message.login_form.remaining_time") }} <span v-if="!resendCodeTempalate">{{ remainingTime }} s</span>
            <span
              v-else
              @click="resendCode()"
            >{{ $t('message.login_form.resend') }}</span>
          </div>
          <div v-if="this.errorLogin">
            <AlertBlock :message="this.errorLogin.email" />
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock,
    LottieAnimation: Vue3Lottie
  },
  data () {
    return {
      sendCode: true,
      errorLogin: '',
      errorCode: '',
      email: '',
      code: '',
      isLoading: false,
      codeResent: false,
      errorLoginMessage: null,
      remainingTime: 60,
      resendCodeTempalate: false
    }
  },
  watch: {
    email: {
      deep: true,
      handler () {
        this.errorLogin = null
      },
      immediate: true
    },
    code: {
      deep: true,
      handler () {
        this.errorCode = null
      },
      immediate: true
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  methods: {
    sendOtp (user) {
      this.email = user.email
      this.isLoading = true
      this.startTimer()
      this.$store.dispatch('auth/sendOtp', user).then(
        (res) => {
          this.errorLogin = null
          this.isLoading = false
          this.sendCode = false
        },
        (error) => {
          this.isLoading = false
          this.errorLogin = error.response.data.errors
          this.errorLoginMessage = error.response.data.message
        }
      )
    },
    login (user) {
      user.email = this.email
      this.isLoading = true
      this.$store.dispatch('auth/login', user).then(
        (res) => {
          this.sendCode = false
          if (res.full_registration === false) {
            this.$router.push('/first-login')
          } else this.$router.push('/dashboard/main')
          this.errorLogin = null
          this.isLoading = false
        },
        (error) => {
          this.isLoading = false
          this.errorCode = error.response.data.errors
        }
      )
    },
    resendCode () {
      const email = {
        email: this.email
      }
      this.code = ''
      this.resendCodeTempalate = false
      this.remainingTime = 60
      this.$store.dispatch('auth/sendOtp', email).then(
        (res) => {
          this.sendCode = false
          this.codeResent = true
          this.startTimer()
        },
        (error) => {
          this.loading = false
          this.errorLogin = error.response.data.errors
        }
      )
    },
    goToChangeEmail () {
      this.sendCode = true
    },
    startTimer () {
      this.timerInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--
        } else {
          clearInterval(this.timerInterval)
          this.resendCodeTempalate = true
        }
      }, 1000)
    }
  }
})
</script>

<style lang="scss" scoped>
.login {
  &__button {
    @include first-button;
    width: 400px;
    margin: 30px 0 0 0;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 10px;
    max-width: 300px;
    text-align: center;
    margin: auto;
  }

  &__resend-text {
    font-size: 12px;
    color: #8c8c8c;
    padding-top: 10px;
    height: 20px;
  }

  &__resend-group {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 10px;
    font-size: 18px;
    line-height: 18px;
    position: relative;
    text-align: left;

    ::v-deep(.alert-danger) {
      border: none;
      top: 25px;
      left: 85px;
    }
  }

  &__button-back {
    width: 130px;
    margin-right: 20px;
  }

  &__resend-button {
    padding-left: 15px;
    color: #678659;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__button-confirm {
    width: 400px;
  }

  &__back-group {
    display: flex;
    margin-top: 30px;
  }

  .error {
    position: absolute;
    left: 0;
    color: #ef5350;
    font-size: 12px;
    line-height: 14px;
    border-top: 1px solid #ef5350;
    width: 100%;
    text-align: left;
    padding-top: 3px;
    z-index: 1;
    max-height: 30px;
    overflow: hidden;
  }

  .timer {
    padding-top: 15px;
    span {
      font-weight: 500;
      display: inline-block;
      margin-left: 5px;
      color: #678659;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
.form-group {
  position: relative;
}

@media (max-width: $break-sm) {
  .login {
    &__button {
      width: 100%;
      font-size: 16px;
    }
    &__subtitle {
      font-size: 16px;
    }
    &__resend-group {
      font-size: 16px;
    }
  }
  .form-group {
    position: relative;
  }
}
</style>
