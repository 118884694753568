import IntegrationsService from '@/services/integrations.service'
import router from '@/router'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const integrations = {
  namespaced: true,
  state: initialState,
  actions: {
    getIntegrations ({ commit }: any, query: string) {
      return IntegrationsService.getIntegrations(query).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getIntegrationsList ({ commit }: any) {
      return IntegrationsService.getIntegrationsList().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    unlinkIntegrations ({ commit }: any, integrationId: string) {
      return IntegrationsService.unlinkIntegrations(integrationId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    authorizeNuki ({ commit }: any) {
      return IntegrationsService.authorizeNuki().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    authorizeSwitchBot ({ commit }: any, data: any) {
      return IntegrationsService.authorizeSwitchBot(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    authorizeTuya ({ commit }: any, data: any) {
      return IntegrationsService.authorizeTuya(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    checkConnection ({ commit }: any, data: any) {
      return IntegrationsService.checkConnection(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
