const message: any = {
  log_in: 'Login',
  email: 'E-Mail',
  log_out: 'Abmelden',
  back: 'Zurück',
  next: 'Weiter',
  choose: 'Auswählen',
  delete: 'Löschen',
  confirm: 'Bestätigen',
  copy: 'Kopieren',
  yes: 'Ja',
  save: 'Speichern',
  ok: 'Ok',
  add: 'Hinzufügen',
  cancel: 'Abbrechen',
  link_copied: 'Link kopiert',
  please_choose_language_of_invite: 'Bitte wählen Sie die Sprache der Einladung aus',
  join_our_fatflow_app_german: 'Um die Besichtigung durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy:',
  join_our_fatflow_app_german_handower: 'Um die Übergabe durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy:',
  thanks_for_the_interest_in_the_property_handower: 'Übergabe durchzuführen, senden Sie bitte folgende Nachricht an den neuen Mieter:',
  join_our_fatflow_app: 'To conduct the visit, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone:',
  join_our_fatflow_app_handower: 'To conduct the handover, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone:',
  are_you_sure: 'Sind Sie sicher?',
  actions: 'Aktionen',
  actions_hint: 'Überblick weiterer Funktionen & Aktionen',
  actions_hint_unit_table_building: ' Überblick über weitere Funktionen',
  download: 'Download with',
  google_play: 'Google play',
  apple_store: 'Apple store',
  done: 'Erledigt',
  step: 'Schritt',
  refresh: 'Aktualisieren',
  login_form: {
    password: 'Passwort',
    get_the_code: 'Code anfragen',
    please_check_your_mail: 'Bitte prüfen Sie Ihr E-Mail-Postfach und geben den Bestätigungscode ein',
    code: 'Bestätigungscode',
    resend_text: 'Bestätigungscode nicht erhalten?',
    resend: 'Erneut senden',
    login: 'Anmelden',
    sent_code_again_text: 'Der Bestätigungscode wurde erneut an Ihre E-Mail gesendet',
    remaining_time: 'Verbleibende Zeit:'
  },
  first_login: {
    title: 'Willkommen bei Flatflow',
    text: 'Tragen Sie bitte Ihre Informationen ein, um die Anmeldung abzuschließen'
  },
  nav: {
    dashboard: 'Dashboard',
    property: 'Einheiten',
    integrations: 'Integrationen',
    settings: 'Einstellungen',
    smart_devices: 'Smart-Devices'
  },
  settings: {
    title: 'Einstellungen',
    language: 'Sprache',
    edit: 'Bearbeiten',
    first_name: 'Vorname',
    last_name: 'Nachname',
    phone_number: 'Telefonnummer'
  },
  integrations: {
    title: 'Integrationen',
    add_integration: 'Konto hinzufügen',
    client_id: 'Kunden ID',
    client_secret: 'Kunden Code',
    more_info: 'Zusätzliche Informationen',
    search_by: 'Nach E-Mail oder Konto Typ suchen',
    account_type: 'Konto Typ',
    unlink_account: 'Konto entfernen',
    action_hint: 'Integrierte Konten entfernen',
    has_integration_popup: 'Um diese Funktion nutzen zu können, verbinden Sie bitte Ihr Cronofy-Konto unter Integrationen',
    no_integrations_yet: 'Aktuell keine Integrationen'
  },
  property: {
    property: 'Einheiten',
    add_building: 'Gebäude hinzufügen',
    unit_overview: 'Wohnungsüberblick',
    buildings_overview: 'Gebäudeüberblick',
    add_new_unit: 'Wohnung hinzufügen',
    units: 'Wohnungen',
    sure_delete_building: 'Sind Sie sicher, dass Sie das Gebäude löschen wollen?',
    search: 'Address suchen ',
    create_edit_slots: 'Slots erstellen/bearbeiten',
    first_day: 'Erster verfügbarer',
    first_day_hint: 'Datum an dem die Besichtigungen starten sollen',
    available_days: 'Verfügbare Tage',
    available_days_hint: 'Tage an denen Besichtigungen durchgeführt werden können',
    daily_availability: 'Zeitraum',
    daily_availability_hint: 'Uhrzeit in den Besichtigungen durchgeführt werden können',
    select_all_or_custom_days: 'Alle oder benutzerdefinierte Tage auswählen',
    beginning_time: 'Startzeit',
    duration: 'Dauer, Min',
    end_time: 'Endzeit',
    create_slots: 'Slots erstellen',
    discard: 'Abbrechen',
    add_unit: 'Wohnung hinzufügen',
    edit_unit: 'Wohnung bearbeiten',
    unit_information: 'Informationen',
    add_unit_barriers: 'Barriere hinzufügen',
    add_unit_barrier: 'Barriere hinzufügen',
    orientation_barrier: 'Orientierungshilfe',
    smart_lock: 'Smart-Lock',
    next: 'Weiter',
    custom_unit_name_reference: 'Objektnummer (Nur für Sie sichtbar zur Identifizierung der Wohnungen)',
    delete_unit: 'Sind Sie sicher, dass Sie die Wohnung löschen möchten?',
    choose_unit: 'Wohnung auswählen',
    unit_details: 'Weitere Informationen (Optional)',
    unit_details_hint: 'Weitere Informationen zur Wohnung',
    enter_a_link: 'Fügen Sie einen Link zum Exposé der Wohnung ein',
    viewing_options: 'Besichtigungsoptionen',
    viewing_options_hint: 'Weitere Optionen und Einstellungen zur Durchführung der Besichtigungen',
    unpublish: 'Deaktivieren',
    publish: 'Veröffentlichen',
    link_with_unit_details: 'Link mit Details zur Wohnung',
    visit_barriers: 'Besichtigungsbarrieren',
    barriers_for_smart_handovers: 'Barrieren für smarte Übergaben',
    post_handover_barriers: 'Barrieren nach der Übergabe',
    slots: 'Slots',
    want_to_add_visit_slots_now: 'Möchten Sie Besichtigungstermine hinzufügen?',
    are_you_sure_you_want_to: 'Sind Sie sicher?',
    german: 'Deutsch',
    english: 'Englisch',
    combination: 'Kombination',
    thanks_for_the_interest_in_the_property: 'Um die Besichtigung durchzuführen, senden Sie bitte folgende Nachricht an den Interessenten:',
    building_address: 'Adresse',
    unit_name: 'Wohnung',
    visit_link: 'Besichtigungslink',
    sh_link: 'Übergabelink',
    units_listed_of_units_total: '# veröffentlichte Wohnungen / Wohnungen Gesamt',
    more_info_about_building: 'Weitere Gebäudeinformationen',
    edit_building: 'Gebäude bearbeiten',
    create_or_edit_slots: 'Slots erstellen & bearbeiten',
    more_info_about_unit: 'Weitere Wohnungsinformationen',
    copy_unit: 'Wohnung kopieren',
    please_add_all_barriers: 'Bitte fügen Sie hier alle Barrieren / Besuchsschritte ein, die für alle Wohnungen im Gebäude relevant sind. Barrieren für einzelne Wohnungen, wie z.B. die Wohnungstür, sollten als Wohnungsbarrieren hinzugefügt werden. Die Reihenfolge der Barrieren ist in diesem Schritt irrelevant und kann später angepasst werden.',
    please_add_unit_barriers: 'Bitte fügen Sie hier alle Barrieren / Besuchsschritte ein, die nur für diese Wohnung relevant sind. Die Reihenfolge der Barrieren ist in diesem Schritt irrelevant und kann später angepasst werden.',
    add_unit_visit_barriers_choose_order: 'Barrieren auswählen & Reihenfolge festlegen',
    please_drag_and_drop_barriers: 'Bitte ziehen Sie die Barrieren (Übergreifende und wohnungsspezifische) von links in gewünschter Reihenfolge in das rechte Feld "Besichtigungsschritte".',
    common_barriers: 'Übergreifende Barrieren',
    unit_barriers: 'Wohnungsbarrieren',
    visit_flow: 'Besichtigungsschritte',
    different_selection: 'Unterschiedliche Auswahl/Reihenfolge für smarte Übergaben (Bitte ignorieren, falls keine smarten Übergaben durchgeführt werden sollen)',
    handover_flow: 'Übergabeablauf',
    unit_overview_hint: 'Überblick über weitere Funktionen',
    buildings_overview_hint: 'Überblick über weitere Funktionen',
    copy_units: 'Wohnung kopieren',
    edit_slots: 'Slots bearbeiten',
    allow_tenants: 'Anrufe von Interessenten während der Besichtigung erlauben',
    notify_me_about_started_visits: 'Benachrichtigung bei Beginn einer Besichtigung',
    automatically_unpublish_after: 'Deaktivierung der Anzeige nach',
    automatically_unpublish_after_second_part: 'Besuchen',
    per_visit: '(+ 1,49€ pro Besichtigung)',
    save_visits_to_my_calendar: 'Besichtigungen in Ihren Kalender eintragen',
    only_allow_visit: 'Besichtigungen nur zu freien Terminen in Ihrem Kalender möglich',
    only_allow_veriff_verified_visitors: 'Verifizierung der Interessenten  vor der Besichtigung',
    please_set_the_visit_slots_before_publishing_this_unit: 'Bitte legen Sie die Besichtigungszeiten fest, damit die Einheit veröffentlicht werden kann',
    update_slots_text: 'Sie haben bereits Besichtigungsslots festgelegt. Wenn Sie auf "Weiter" klicken, werden diese auch für die Übergabe genutzt. Wenn Sie die Zeiten ändern möchten, klicken Sie auf "Slots aktualisieren',
    want_to_edit_visit_slots_now: 'Sie wollen Besichtigungsslots bearbeiten?',
    unit_has_draft: 'Für die Einheit gibt es bereits eine Entwurfsversion',
    unit_will_be_finished: 'Diese Wohnung wird als Entwurf gespeichert, da noch keine Besichtigungsslots erstellt wurden',
    publishing_unit: 'Durch das Veröffentlichen dieser Wohnung wird die aktuell geplante Übergabe storniert',
    an_active_visit_taking_place: 'Eine Besichtigung findet gerade statt. Sie können diese Wohnung nicht deaktivieren. Bitte warten Sie, bis die Besichtigung abgeschlossen ist',
    update_slots: 'Slots aktualisieren',
    editing_unit_current_handover_link: 'Durch die Bearbeitung dieser Wohnung wird der aktuelle Übergabelink ungültig',
    you_cannot_edit_unit: 'Sie können eine Wohnung nicht bearbeiten, solange diese veröffentlicht ist. Bitte deaktivieren Sie diese zuerst',
    active_visit_cannot_edit_unit: 'Die Informationen können nicht angepasst werden, da aktuell eine Besichtigung durchgeführt wird',
    search_by_unit_name: 'Nach Wohnung suchen',
    no_units_yet: 'Aktuelle keine Wohnungen',
    no_bilding_yet: 'Aktuell keine Gebäude',
    post_visit_flow: 'Barrieren nach Besichtigung',
    warning: 'Achtung!',
    sure_delete_building_has_unit: 'Alle Einheiten werden mitgelöscht. Sind Sie sich sicher, dass Gebäude löschen zu wollen?',
    smart_key_box: 'Smarte Schlüsselbox',
    confirmation_barrier: 'Foto-Barriere',
    inventory: 'Inventar'
  },
  building: {
    discard: 'Abbrechen',
    barrier_title: 'Barriere-Titel *',
    add_new: 'Gebäude hinzufügen',
    barriers_title: 'Übergreifende Barrieren / Besuchsschritte',
    address_selection: 'Adresseingabe',
    start_typing: 'Geben Sie bitte die Adresse des Gebäudes ein*',
    address: 'Adresse',
    move_pin: 'Bitte schieben Sie den Pin so genau wie möglich auf das Gebäude',
    save_and_add_unit: 'Speichern und Wohnung hinzufügen',
    change_address: 'Adresse ändern',
    confirm_pin: 'Bestätigen',
    building_barriers: 'Übergreifende Barrieren / Besuchsschritte',
    smart_lock: 'Smarte Schlösser',
    check_connection: 'Verbindung prüfen',
    add_barrier: 'Barriere hinzufügen',
    pictures_and_description: 'Bilder und Beschreibung des Gebäudes',
    upload: 'Hochladen',
    barrier_description: 'Beschreibung der Barriere *',
    barrier_description_prompt: 'z. B. "Öffnen Sie diese Tür und gehen Sie anschließend in den dritten Stock" oder "Sie finden diese Tür, wenn Sie den Parkplatz des Gebäudes betreten"',
    barrier_add_image_title: 'Foto oder Video hinzufügen',
    barrier_add_image: 'Nur jpg, png, mov oder mp4',
    orientation_barrier: 'Orientierungshilfe'
  },
  dashboard: {
    upcoming: 'Anstehende Besichtigungen',
    history: 'Durchgeführte Besichtigungen',
    all_planned: 'Anzahl der Besichtigungen:',
    no_results_found: 'Keine Ergebnisse gefunden',
    address: 'Adresse',
    create_slots: 'Slots erstellen',
    my_appointments: 'Meine Besichtigungen',
    my_smart_devices: 'Meine "Smart devices"',
    delete_appointments: 'Sind Sie sicher, dass Sie Termine löschen wollen?',
    search_appointments: 'Nach Adresse, Interessent oder Wohnungsname',
    search_device: 'Nach Name, Gerätetyp oder Adresse suchen',
    name: 'Name',
    barrier_title: 'Barriere',
    device_type: 'Gerätetyp',
    visitor: 'Interessent',
    no_visits_yet: 'Keine anstehenden Besichtigungen',
    no_historic_visits: 'Keine durchgeführten Besichtigungen',
    no_smart_devices_yet: 'Aktuell keine "Smart Devices" vorhanden'
  },
  calendar: {
    all_days: 'Alle Tage',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag'
  },
  inventory: {
    title: 'Inventar',
    optional: 'Dieses Item ist optional',
    please_select_room: 'Bitte wählen Sie zuerst ein Zimmer aus und fügen Sie dann die Möbel hinzu.',
    dont_have_room: 'Ein bestimmter Raum ist nicht in der Liste enthalten?',
    other: 'Weitere',
    add_other: 'Weitere hinzufügen',
    edit_room: 'Zimmer bearbeiten',
    add_new_inventory: 'Neues Inventar hinzufügen',
    add_description: 'Beschreibung hinzufügen',
    description: 'Beschreibung',
    defects_inventory: 'Sie können Mängel für das Inventar hinzufügen.',
    add_room_photo: 'Zimmerfoto hinzufügen',
    add_inventory_photo: 'Inventarfoto hinzufügen',
    title_placeholder: 'Titel'
  }
}

export default message
