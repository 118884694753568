<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `FlatFlow` : `FlatFlow` }}</template>
  </metainfo>
  <router-view v-slot="{ Component }">
    <transition
      name="fade"
      mode="out-in"
    >
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
  }
})
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-family: $fontFamily;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $firstColor;
  background: #f5f5f5;
  min-height: 100vh;
}
.home-section__top-section-right .svg {
  display: none;
}

.form-control {
  border: none;
  border-bottom: 1px solid #d2d4d8;
  box-sizing: border-box;
  width: 400px;
  padding-top: 18px !important;
  background: #f5f5f5;
  font-size: 18px;

  &:focus + label {
    display: inline-block;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #8c8c8c;
  }

  &:focus {
    outline: none;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset;
}
.form-group {
  position: relative;
}
textarea {
  font-family: $fontFamily;
  max-width: 466px;
  min-width: 466px;
  min-height: 100px;
}
.form-group input {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.form-group-label {
  display: none;
  position: absolute;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #8c8c8c;
  top: 0;
  opacity: 0;
  animation: ani 1s forwards;

  &.active {
    display: block;
    left: 1px;
  }
}
.error-feedback {
  color: #ff444f;
  font-size: 12px;
  position: absolute;
  bottom: -18px;
  right: 0;
  text-align: left;
  border-top: 1px solid;
  width: 100%;
  padding-top: 3px !important;
}
.error {
  color: #ff444f;
}
.error-delete {
  color: #ff444f;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.select {
  width: 200px;
  height: 50px;
  border: 1px solid $firstColor;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin: 20px 0 0 0;
}
.select-body {
  text-align: left;
}

input:focus::placeholder {
  color: transparent;
  opacity: 0;
}

textarea:focus::placeholder {
  color: transparent;
  opacity: 0;
}

input:focus-visible {
  overflow: hidden;
  outline: none;
}
input:focus {
  overflow: hidden;
  outline: none;
}
input:-webkit-autofill {
  background: transparent !important;
}

.lottie-animation-container {
  position: fixed;
  left: 0;
  top: 0;
  background: #000000;
  background-color: #000000!important;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 100000000;

  svg {
    display: block;
    width: 200px !important;
    height: auto !important;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%) !important;
  }
}

@keyframes ani {
  0% {
    opacity: 0;
    transform: translateY(150%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: $break-sm) {
  .form-control {
    width: 100%;
  }
  .form-group {
    width: 100%;

    input {
      font-size: 16px;
    }
  }
  .form-control {
    &::placeholder {
      font-size: 16px;
    }
  }
}
</style>
