
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import HomeSection from '@/components/sections/HomeSection.vue'

export default defineComponent({
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    useMeta({
      title: t('message.title.login')
    })
  },
  mounted () {
    const loggedIn = localStorage.getItem('user')
    if (loggedIn !== undefined) {
      this.$router.push('/dashboard/main')
    }
  },
  name: 'Home',
  components: {
    HomeSection
  }
})
