import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class AuthService {
  sendOtp (user: any) {
    return axios
      .post(API_URL + 'provider/auth/send-otp', {
        email: user.email
      }, { headers: authHeader() })
  }

  login (user: any) {
    return axios
      .post(API_URL + 'provider/auth/login', {
        email: user.email,
        code: user.code
      }, { headers: authHeader() })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data.access_token))
        }
        return response.data
      })
  }

  getCountryCodes () {
    return axios
      .get(API_URL + 'provider/auth/fields/country-codes')
      .then(response => {
        return response.data
      })
  }

  firstTimeLogin (data: any) {
    return axios.put(API_URL + 'provider/profile/first-time-login', {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      language_code: data.language_code
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  logout () {
    return axios
      .post(API_URL + 'provider/auth/logout', {
      }, { headers: authHeader() })
  }

  checkPassword (data) {
    return axios
      .post(API_URL + 'owner/dashboard/profile/password/check', { password: data.password }, { headers: authHeader() })
  }

  newPassword (data: any) {
    return axios
      .post(API_URL + 'owner/dashboard/password/new', {
        password: data.password,
        password_confirmation: data.password_confirmation
      }, { headers: authHeader() })
  }

  emailVerify (data: any) {
    return axios
      .post(API_URL + 'auth/email/verify', {
        token: data
      }, { headers: authHeader() })
  }

  passwordRecoveryEmail (data: any) {
    return axios
      .post(API_URL + 'owner/dashboard/password/send-code', {
        email: data.email
      }, { headers: authHeader() })
  }

  passwordCheckCode (data: any) {
    return axios
      .post(API_URL + 'owner/dashboard/password/check-code', {
        email: data.email,
        code: data.code
      }, { headers: authHeader() })
  }

  register (user: any) {
    return axios.post(API_URL + 'owner/dashboard/register', {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      password: user.password,
      password_confirmation: user.password_confirmation
    })
  }

  changePassword (data) {
    return axios.post(API_URL + 'users/changePassword', {
      current_password: data.current_password,
      new_password: data.new_password,
      password_confirmation: data.password_confirmation
    },
    { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new AuthService()
