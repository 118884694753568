import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class InventoryService {
  getRooms () {
    return axios.get(API_URL + 'provider/default-rooms', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  editRooms (data: any) {
    return axios.put(API_URL + 'provider/units/' + data.unitId + '/rooms/' + data.roomId, {
      name: data.name
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  editInventory (data: any) {
    return axios.put(API_URL + 'provider/units/' + data.unitId + '/rooms/' + data.roomId + '/inventory/' + data.inventoryId, {
      name: data.name,
      description: data.description,
      quantity: data.quantity
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteRooms (data: any) {
    return axios.delete(API_URL + 'provider/units/' + data.unitId + '/rooms/' + data.roomId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteRoomPhoto (data: any) {
    return axios.delete(API_URL + 'provider/units/' + data.unitId + '/rooms/' + data.roomId + '/attachments/' + data.attachmentRoomId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteInventoryPhoto (data: any) {
    return axios.delete(API_URL + 'provider/units/' + data.unitId + '/rooms/' + data.roomId + '/inventory/' + data.inventoryId + '/attachments/' + data.attachmentRoomId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteInventory (data: any) {
    return axios.delete(API_URL + 'provider/units/' + data.unitId + '/rooms/' + data.roomId + '/inventory/' + data.inventoryId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getInventory (roomId: string) {
    return axios.get(API_URL + 'provider/' + roomId + '/default-inventory-items', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  addOtherRoom (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/rooms', {
      name: data.name,
      parent_room_id: data.parent_room_id
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  addOtherInventory (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/rooms/' + data.roomId + '/inventory', {
      name: data.name
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}
export default new InventoryService()
