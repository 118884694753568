import InventiryService from '@/services/inventory.service'
import router from '@/router'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const inventory = {
  namespaced: true,
  state: initialState,
  actions: {
    getRooms ({ commit }) {
      return InventiryService.getRooms().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    editRooms ({ commit }, data: any) {
      return InventiryService.editRooms(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    editInventory ({ commit }, data: any) {
      return InventiryService.editInventory(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    deleteRooms ({ commit }, data: any) {
      return InventiryService.deleteRooms(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    getInventory ({ commit }, roomId: any) {
      return InventiryService.getInventory(roomId).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    addOtherRoom ({ commit }, data: any) {
      return InventiryService.addOtherRoom(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    addOtherInventory ({ commit }, data: any) {
      return InventiryService.addOtherInventory(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    deleteRoomPhoto ({ commit }, data: any) {
      return InventiryService.deleteRoomPhoto(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    deleteInventoryPhoto ({ commit }, data: any) {
      return InventiryService.deleteInventoryPhoto(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    deleteInventory ({ commit }, data: any) {
      return InventiryService.deleteInventory(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  }
}
