import AuthService from '@/services/auth.service'

const user: [] = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    sendOtp ({ commit }: any, user: any) {
      return AuthService.sendOtp(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    login ({ commit }: any, user: any) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    getCountryCodes ({ commit }: any) {
      return AuthService.getCountryCodes().then(
        data => {
          return Promise.resolve(data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    firstTimeLogin ({ commit }: any, user: any) {
      return AuthService.firstTimeLogin(user).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    checkPassword ({ commit }: any, data: any) {
      return AuthService.checkPassword(data).then(
        data => {
          commit('checkPassword Success', data)
          return Promise.resolve(data)
        },
        error => {
          commit('checkPassword Failure')
          return Promise.reject(error)
        }
      )
    },
    passwordRecoveryEmail ({ commit }: any, data: any) {
      return AuthService.passwordRecoveryEmail(data).then(
        response => {
          commit('passwordRecoveryEmail Success')
          return Promise.resolve(response.data)
        },
        error => {
          commit('passwordRecoveryEmail Failure')
          return Promise.reject(error)
        }
      )
    },
    passwordCheckCode ({ commit }: any, data: any) {
      return AuthService.passwordCheckCode(data).then(
        response => {
          commit('passwordCheckCode Success')
          return Promise.resolve(response.data)
        },
        error => {
          commit('passwordCheckCode Failure')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }: any) {
      AuthService.logout()
      commit('logout')
      localStorage.removeItem('user')
      localStorage.removeItem('user_data')
    },
    register ({ commit }: any, user: any) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    emailVerify ({ commit }: any, data: any) {
      return AuthService.emailVerify(data).then(
        response => {
          commit('emailVerifySuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('emailVerifyFailure')
          return Promise.reject(error)
        }
      )
    },
    newPassword ({ commit }: any, data: any) {
      return AuthService.newPassword(data).then(
        response => {
          commit('newPasswordSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('newPasswordFailure')
          return Promise.reject(error)
        }
      )
    },
    changePassword ({ commit }: any, data: any) {
      return AuthService.changePassword(data).then(
        response => {
          commit('changePasswordSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('changePasswordFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    loginSuccess (state: any, user: any) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure (state: any) {
      state.status.loggedIn = false
      state.user = null
    },
    logout (state: any) {
      state.status.loggedIn = false
      state.user = null
    },
    getTransactionId (state: any) {
      state.status.loggedIn = false
    },
    registerSuccess (state: any) {
      state.status.loggedIn = false
    },
    registerFailure (state: any) {
      state.status.loggedIn = false
    },
    changePassword (state: any) {
      state.status.loggedIn = false
    }
  }
}
