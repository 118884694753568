import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class PropertyService {
  // building
  //

  createBuilding () {
    return axios.post(API_URL + 'provider/buildings', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getBuilding (buildingId: string) {
    return axios.get(API_URL + 'provider/buildings/' + buildingId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getBuildings (url: string) {
    return axios.get(API_URL + 'provider/buildings' + url, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  draftBuilding (buildingId: string) {
    return axios.post(API_URL + 'provider/buildings/' + buildingId + '/draft', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  discardBuilding (buildingId) {
    return axios.post(API_URL + 'provider/buildings/' + buildingId + '/discard', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  saveBuildingFirstStep (data: any) {
    return axios.post(API_URL + 'provider/buildings/' + data.url + '/save/1', {
      coordinates: data.coordinates,
      coordinates_2: data.coordinates_2,
      country: data.country,
      country_code: data.country_code,
      city: data.city,
      street: data.street,
      house: data.house,
      zip_code: data.zip_code
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  saveBuildingSecondStep (buildingId: string) {
    return axios.post(API_URL + 'provider/buildings/' + buildingId + '/save', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteBuilding (buildingId: string) {
    return axios.delete(API_URL + 'provider/buildings/' + buildingId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getBuildingUnits (buildingId: string) {
    return axios.get(API_URL + 'provider/buildings/' + buildingId + '/units', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  // units
  //

  сreateUnit (buildingId) {
    return axios.post(API_URL + 'provider/buildings/' + buildingId + '/units', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  draftUnit (unitId) {
    return axios.post(API_URL + 'provider/units/' + unitId + '/draft', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  setPropertiesStepOne (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/save/1', {
      name: data.name
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  setPropertiesStepTwo (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/save/2', {
      visit_barriers: data.visit_barriers,
      different_order: data.different_order,
      handover_barriers: data.handover_barriers,
      post_handover_barriers: data.post_handover_barriers,
      post_visit_barriers: data.post_visit_barriers
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  setPropertiesStepThree (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/save/3', {
      details_link: data.details_link
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  setPropertiesStepFour (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/save/4', {
      options: {
        allow_tenants_call: data.allow_tenants_call,
        notify_started_visits: data.notify_started_visits,
        auto_unpublished: data.auto_unpublished,
        save_to_calendar: data.save_to_calendar,
        allow_bookings_when_available: data.allow_bookings_when_available,
        allow_passbase_verified: data.allow_passbase_verified,
        take_offline_on_handover: data.take_offline_on_handover
      }
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  setProperties (unitId: string) {
    return axios.post(API_URL + 'provider/units/' + unitId + '/save', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getUnit (unitId: string) {
    return axios.get(API_URL + 'provider/units/' + unitId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getUnits (url: string) {
    return axios.get(API_URL + 'provider/units' + url, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  discardUnit (unitId: string) {
    return axios.post(API_URL + 'provider/units/' + unitId + '/discard', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  copyUnit (unitId) {
    return axios.post(API_URL + 'provider/units/' + unitId + '/copy', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  publishUnit (data) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/publish', {
      handover_cancel: data.handover_cancel
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  unpublishedUnit (unitId) {
    return axios.post(API_URL + 'provider/units/' + unitId + '/unpublished', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteUnit (unitId: string) {
    return axios.delete(API_URL + 'provider/units/' + unitId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getLink (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/get-link', {
      type: data.type
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  createSlots (data: any) {
    return axios.post(API_URL + 'provider/units/' + data.unitId + '/timeslot', {
      date_starts_from: data.date_starts_from,
      config: data.config,
      visits_cancel: data.visits_cancel
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getSlots (unitId: string) {
    return axios.get(API_URL + 'provider/units/' + unitId + '/timeslot', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAppointment (appointmentId: string) {
    return axios.get(API_URL + 'provider/appointments/' + appointmentId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new PropertyService()
