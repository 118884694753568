import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a924d6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "locale-changer" }
const _hoisted_2 = ["onUpdate:modelValue", "value", "onClick", "id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales.reverse(), (locale) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `locale-${locale}`
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          "onUpdate:modelValue": ($event: any) => ((_ctx.$i18n.locale) = $event),
          value: locale,
          onClick: ($event: any) => (_ctx.setLocal(locale)),
          id: `${_ctx.preKey}locale-${locale}`
        }, null, 8, _hoisted_2), [
          [_vModelRadio, _ctx.$i18n.locale]
        ]),
        _createElementVNode("label", {
          for: `${_ctx.preKey}locale-${locale}`,
          class: _normalizeClass(['locale-changer__button', locale + this.activeLang ])
        }, _toDisplayString(locale), 11, _hoisted_3)
      ]))
    }), 128))
  ]))
}