import BarriersService from '@/services/barriers.service'
import router from '@/router'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const barriers = {
  namespaced: true,
  state: initialState,
  actions: {
    getDeviceTypes ({ commit }: any) {
      return BarriersService.getDeviceTypes().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getBuildingBarriers ({ commit }: any, buildingId: string) {
      return BarriersService.getBuildingBarriers(buildingId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getUnitBarriers ({ commit }: any, unitId: string) {
      return BarriersService.getUnitBarriers(unitId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    createBuildingBarrier ({ commit }: any, buildingId: string) {
      return BarriersService.createBuildingBarrier(buildingId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteBuildingBarrier ({ commit }: any, ids: any) {
      return BarriersService.deleteBuildingBarrier(ids).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteUnitBarrier ({ commit }: any, ids: any) {
      return BarriersService.deleteUnitBarrier(ids).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getBuildingBarrier ({ commit }: any, data: any) {
      return BarriersService.getBuildingBarrier(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getUnitBarrier ({ commit }: any, data: any) {
      return BarriersService.getUnitBarrier(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    updateBuildingBarrier ({ commit }: any, data: any) {
      return BarriersService.updateBuildingBarrier(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
