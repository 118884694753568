
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LocalChanger',
  data () {
    return {
      activeLang: null
    }
  },
  props: {
    preKey: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  mounted () {
    const lang = JSON.parse(localStorage.getItem('lang'))
    this.activeLang = lang
  },
  methods: {
    setLocal (lang) {
      localStorage.setItem('lang', JSON.stringify(lang))
      this.activeLang = lang
    }
  }
})
