import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/install_our_app',
    name: 'InstallOurApp',
    component: () => import('../views/InstallOurApp.vue')
  },
  {
    path: '/first-login',
    name: 'FirstLogin',
    component: () => import('../components/forms/FirstLogin.vue')
  },
  {
    path: '/dashboard/settings',
    name: 'Settings',
    component: () => import('../views/dashboard/Settings.vue')
  },
  {
    path: '/dashboard/main',
    name: 'MainView',
    component: () => import('../views/dashboard/MainView.vue')
  },
  {
    path: '/dashboard/visit-details/:id',
    name: 'VisitDetails',
    component: () => import('../views/dashboard/VisitDetails.vue')
  },
  {
    path: '/dashboard/integrations',
    name: 'Integrations',
    component: () => import('../views/dashboard/Integrations.vue')
  },

  // Property (building unit) page
  //

  {
    path: '/dashboard/property',
    name: 'Property',
    component: () => import('../views/dashboard/property/Property.vue')
  },

  // Building create page
  //

  {
    path: '/dashboard/create-building',
    name: 'CreateBuilding',
    component: () => import('../views/dashboard/property/CreateBuilding.vue')
  },

  {
    path: '/dashboard/create-building-step-two',
    name: 'CreateBuildingStepTwo',
    component: () => import('../views/dashboard/property/CreateBuildingStepTwo.vue')
  },

  {
    path: '/dashboard/building-details',
    name: 'BuildingBigCard',
    component: () => import('../views/dashboard/property/BuildingBigCard.vue')
  },

  // Unit create
  //

  {
    path: '/dashboard/create-unit-step-one',
    name: 'CreateUnitStepOne',
    component: () => import('../views/dashboard/property/CreateUnitStepOne.vue')
  },

  {
    path: '/dashboard/create-unit-step-two',
    name: 'CreateUnitStepTwo',
    component: () => import('../views/dashboard/property/CreateUnitStepTwo.vue')
  },

  {
    path: '/dashboard/create-unit-step-three',
    name: 'CreateUnitStepThree',
    component: () => import('../views/dashboard/property/CreateUnitStepThree.vue')
  },

  {
    path: '/dashboard/create-unit-step-inventory',
    name: 'CreateUnitStepInventory',
    component: () => import('../views/dashboard/property/CreateUnitStepInventory.vue')
  },

  {
    path: '/dashboard/create-unit-step-four',
    name: 'CreateUnitStepFour',
    component: () => import('../views/dashboard/property/CreateUnitStepFour.vue')
  },

  {
    path: '/dashboard/choose-unit',
    name: 'ChooseUnit',
    component: () => import('../views/dashboard/property/ChooseUnit.vue')
  },

  {
    path: '/dashboard/unit-details',
    name: 'UnitBigCard',
    component: () => import('../views/dashboard/property/UnitBigCard.vue')
  },

  {
    path: '/dashboard/create-slots',
    name: 'CreateSlots',
    component: () => import('../views/dashboard/property/CreateSlots.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/first-login', '/install_our_app']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
