import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class DevicesService {
  getDevices (query: string) {
    return axios.get(API_URL + 'provider/devices' + query, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getDevice (deviceId) {
    return axios.get(API_URL + 'provider/devices' + deviceId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  openDevice (deviceId) {
    return axios.post(API_URL + 'provider/devices/' + deviceId + '/open', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  closeDevice (deviceId) {
    return axios.post(API_URL + 'provider/devices/' + deviceId + '/close', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAppointments (query: string) {
    return axios.get(API_URL + 'provider/appointments' + query, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  saveCallRequest (visitId: string) {
    return axios.post(API_URL + 'provider/appointments/' + visitId + '/call-check', {}, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  cancelAppointments (visitId: string) {
    return axios.post(API_URL + 'provider/appointments/' + visitId + '/cancel', {}, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  deleteAppointments (visitId: string) {
    return axios.delete(API_URL + 'provider/appointments/' + visitId, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  refresh () {
    return axios.post(API_URL + 'provider/devices/sync', {}, { headers: authHeader() })
      .then(response => {
        return response
      })
  }
}

export default new DevicesService()
