
import { defineComponent } from 'vue'
import LogInForm from '@/components/forms/Login.vue'
import LocalChanger from '@/components/molecules/LocalChanger.vue'
import BackgroundLogo from '@/components/molecules/BackgroundLogo.vue'

export default defineComponent({
  name: 'HomeSection',
  data () {
    return {
      users: null
    }
  },
  created () {
    let lang = JSON.parse(localStorage.getItem('lang'))
    if (lang === null) {
      lang = 'en'
      localStorage.setItem('lang', JSON.stringify(lang))
    }
  },
  methods: {
  },
  components: {
    LogInForm,
    LocalChanger,
    BackgroundLogo
  }
})
