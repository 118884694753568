
import { defineComponent } from 'vue'
import LoginLogo from '@/assets/svg/login-logo.svg?inline'

export default defineComponent({
  name: 'BackgroundLogo',
  data () {
    return {
    }
  },
  components: {
    LoginLogo
  },
  methods: {
  }
})
