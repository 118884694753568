import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class BarriersService {
  getDeviceTypes () {
    return axios.get(API_URL + 'provider/buildings/fields/device-types', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getBuildingBarriers (buildingId) {
    return axios.get(API_URL + 'provider/buildings/' + buildingId + '/barriers', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  createBuildingBarrier (buildingId) {
    return axios.post(API_URL + 'provider/buildings/' + buildingId + '/barriers', {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteBuildingBarrier (ids) {
    return axios.delete(API_URL + 'provider/buildings/' + ids.buildingId + '/barriers/' + ids.barrierId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  deleteUnitBarrier (ids) {
    return axios.delete(API_URL + 'provider/units/' + ids.unitId + '/barriers/' + ids.barrierId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getUnitBarriers (unitId) {
    return axios.get(API_URL + 'provider/units/' + unitId + '/barriers', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getBuildingBarrier (data: any) {
    return axios.get(API_URL + 'provider/buildings/' + data.buildingId + '/barriers/' + data.barrierId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getUnitBarrier (data: any) {
    return axios.get(API_URL + 'provider/units/' + data.buildingId + '/barriers/' + data.barrierId, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  updateBuildingBarrier (data: any) {
    return axios.put(API_URL + 'provider/buildings/' + data.buildingId + '/barriers/' + data.barrierId, {}, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new BarriersService()
