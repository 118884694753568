import DevisesService from '@/services/devices.service'
import router from '@/router'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const devices = {
  namespaced: true,
  state: initialState,
  actions: {
    getDevices ({ commit }: any, query: string) {
      return DevisesService.getDevices(query).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    getDevice ({ commit }: any, deviceId: any) {
      return DevisesService.getDevice(deviceId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    openDevice ({ commit }: any, deviceId: any) {
      return DevisesService.openDevice(deviceId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    closeDevice ({ commit }: any, deviceId: any) {
      return DevisesService.closeDevice(deviceId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    getAppointments ({ commit }: any, query: string) {
      return DevisesService.getAppointments(query).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    saveCallRequest ({ commit }: any, visitId: string) {
      return DevisesService.saveCallRequest(visitId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    cancelAppointments ({ commit }: any, visitId: string) {
      return DevisesService.cancelAppointments(visitId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    refresh ({ commit }: any) {
      return DevisesService.refresh().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    },

    deleteAppointments ({ commit }: any, visitId: string) {
      return DevisesService.deleteAppointments(visitId).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          if (error.response.status === 401) {
            router.push('/')
            localStorage.removeItem('user')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
