import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class UserService {
  getProfile () {
    return axios.get(API_URL + 'provider/profile', { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  editProfile (data: any) {
    return axios.put(API_URL + 'provider/profile', {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone
    }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new UserService()
